import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'
import CoverageStatus from '../../Generic/CoverageStatus'

const OtherEventsOverall = ({
  data, coverageStatus, coverageProgress, playerId, playerName,
}) => {
  const { t } = useTranslation()


  const sumKey = subject => _.sumBy(data, (injury) => {
    if (injury.parent_injury_id) return 0
    if (_.find(data, { parent_injury_id: injury.id })) {
      const subInjuries = _.filter(data, { parent_injury_id: injury.id })
      return _.sumBy(subInjuries, subject) > 0 ? _.sumBy(subInjuries, subject) : injury[subject]
    }
    return injury[subject]
  })

  const recoverySum = useMemo(() => {
    const sum = sumKey('recovery_days')
    if (sum == null) {
      return t('other-events-nd')
    }
    return sum
  }, [data])
  const missedMatchesSum = useMemo(() => {
    const sum = sumKey('missed_matches')
    if (sum == null) {
      return t('other-events-nd')
    }
    return sum
  }, [data])

  return (
    <Overall>
      <CoverageStatus
        status={coverageStatus}
        progress={coverageProgress}
        playerId={playerId}
        playerName={playerName} />
      <Item>
        <ItemTitle>{t('Total other events')}</ItemTitle>
        <ItemValue>{data.length}</ItemValue>
      </Item>
      <Item>
        <ItemTitle>{t('other-events-total-days')}</ItemTitle>
        <ItemValue>{recoverySum}</ItemValue>
      </Item>
      <Item>
        <ItemTitle>{t('Total missed matches')}</ItemTitle>
        <ItemValue>{missedMatchesSum}</ItemValue>
      </Item>
    </Overall>
  )
}

export default OtherEventsOverall

const Overall = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  gap: 30px;
  @media (max-width: 1200px) {
    gap: 0px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    align-items: stretch;
  }
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  @media (max-width: 800px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 10px 15px;
    &:not(:last-child) {
      border-bottom: 1px solid ${cssvar('borderColor')};
    }
  }
`
export const ItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 800px) {
  }
`
export const ItemValue = styled.div`
  color: ${cssvar('injuryColor')};
  font-size: 18px;
  font-weight: 550;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`
