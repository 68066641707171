import axios from 'axios'
import { BASE_V2_URL } from '../api'
import history from '../history'
// import { readToken } from './auth'

/**
 *
 * @param {String} playerId
 * @param {String} type
 * @param {('last_24_months' | 'current_state' | 'current_season' | 'complete')} detailLevel
 * @returns
 */
export const createEnquiry = (playerId, type = 'injury', detailLevel = 'complete') => (
  axios.post(`${BASE_V2_URL}/enquiries`, { playerId, type, detailLevel })
)

export const readEnquiry = (id, tag) => {
  axios
    .put(`${BASE_V2_URL}/enquiries/${id}/markasread`)
    .then(() => {
      history.push(`/explore/${tag}/injuries`)
    })
}

export const readAllEnquiries = () => {
  axios.put(`${BASE_V2_URL}/enquiries/markasread`)
}