import React from 'react'
import Dialog from '../Dialog'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { cssvar } from '../../styles/var'
import { createEnquiry } from '../../api/enquiries'
import { Select } from 'antd'

const EnquiryRequestDialog = (props) => {
  const { t } = useTranslation()

  const [responseDialogVisible, setResponseDialogVisible] = React.useState(false)
  const [error, setError] = React.useState(false)

  // 'last_24_months' | 'current_state' | 'current_season' | 'complete'
  const [detailLevel, setDetailLevel] = React.useState('complete')

  const {
    open, onClose, playerId, playerName, onSuccess, onError,
  } = props

  const handleReqClick = (playerId, detailLevel) => {
    createEnquiry(playerId, 'injury', detailLevel)
      .then((res) => {
        if (res.status === 429) {
          setResponseDialogVisible(true)
          setError(true)
          onError()
          return
        }
        setError(false)
        onSuccess()
      })
  }

  return (
    <>
      <Dialog
        open={responseDialogVisible}
        title={error ? t('limit-reached') : t('analysis-in-progress')}
        onClose={() => setResponseDialogVisible(false)}>
        <DialogText>
          {error ? t('enquiry-request-error') : t('enquiry-request-feedback')}
        </DialogText>
      </Dialog>
      <Dialog
        open={open}
        title={t('request-analysis')}
        onClose={onClose}
        footer={(
          <Buttons>
            <Button onClick={() => handleReqClick(playerId, detailLevel)}>{t('confirm-request-yes')}</Button>
            <Button cancel onClick={() => onClose()}>{t('confirm-request-cancel')}</Button>
          </Buttons>
        )}>
        <DialogText>
          <span style={{ paddingBottom: '10px' }}>
            {t('confirm-request')}
            {' '}
            <b>{playerName}</b>
            ?
          </span>
          <br />
          <div
            style={{
              marginTop: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >

            {t('enquiry-choose-detail-level')}
            <Select onChange={setDetailLevel} value={detailLevel} style={{ marginLeft: '10px', minWidth: '160px' }}>
              <option value='current_state'>{t('enquiry-detail-level-current_state')}</option>
              <option value='current_season'>{t('enquiry-detail-level-current_season')}</option>
              <option value='last_24_months'>{t('enquiry-detail-level-last-24-months')}</option>
              <option value='complete'>{t('enquiry-detail-level-complete')}</option>
            </Select>
          </div>
        </DialogText>
      </Dialog>
    </>
  )
}

export default EnquiryRequestDialog


const DialogText = styled.div`
  color: ${cssvar('mainColor')};
  text-align: center;
  white-space: normal;
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
`
const Button = styled.div`
  padding: 6px 12px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  background-color: ${cssvar('primaryColor')};
  border-radius: 4px;
  transition: .3s;
  cursor: pointer;
  &:hover {
    background-color: ${cssvar('primaryHoverColor')};
  }
  ${props => props.cancel && css`
    color: ${cssvar('mainColor')};
    background-color: #ddd;
    &:hover {
      background-color: #ccc;
    }
  `}
`