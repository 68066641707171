import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { cssvar } from '../../styles/var'
import EnquiryRequestDialog from './EnquiryRequestDialog'

export const CoverageStatus = ({
  status, progress, playerId, playerName,
}) => {
  const { t } = useTranslation()
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [localCoverageProgress, setLocalCoverageProgress] = useState(progress)

  return (
    <Item>
      <ItemTitle>{t(status)}</ItemTitle>
      <ItemValue>
        <CoverageStatusContainer>
          <FeedbackBadge status={status} />
          {status !== 'fully_covered' && (
            <RequestLink
              status={status}
              disabled={localCoverageProgress}
              onClick={() => (!localCoverageProgress ? setConfirmDialog(true) : null)}>
              {localCoverageProgress ? t('analysis-in-progress') : t('request-analysis')}
            </RequestLink>
          )}
        </CoverageStatusContainer>
      </ItemValue>

      <EnquiryRequestDialog
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        playerId={playerId}
        playerName={playerName}
        onSuccess={() => {
          setConfirmDialog(false)
          setLocalCoverageProgress(true)
        }}
        onError={() => setConfirmDialog(false)}
      />
    </Item>
  )
}

export default CoverageStatus

const colors = {
  fully_covered: '#3ed771',
  partially_covered: '#cd8300',
  uncovered: cssvar('injuryColor'),
}

const CoverageStatusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  @media (max-width: 640px) {
    justify-content: flex-end;
    margin: 0;
  }
`

const FeedbackBadge = styled.div`
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => colors[props.status]};
`

const RequestLink = styled.div`
  flex: 1 1 auto;
  font-size: 14px;
  text-transform: none;
  border-bottom: ${({ disabled, status }) => (disabled ? 'none' : `1px dashed ${colors[status]}`)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  &&& {
    color: ${props => colors[props.status]};
  }
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  @media (max-width: 800px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 10px 15px;
    &:not(:last-child) {
      border-bottom: 1px solid ${cssvar('borderColor')};
    }
  }
`
export const ItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 800px) {
  }
`
export const ItemValue = styled.div`
  color: ${cssvar('injuryColor')};
  font-size: 18px;
  font-weight: 550;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`
