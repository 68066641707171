import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CoverageStatus from '../../Generic/CoverageStatus'
import { cssvar } from '../../../styles/var'

const InjuriesOverall = ({ data, coverageStatus, coverageProgress, playerId, playerName }) => {
  const { t } = useTranslation()

  const sumRecovery = (subject) => {
    return _.sumBy(data, injury => {
      if (!!injury.parent_injury_id) return 0
      if (_.find(data, { parent_injury_id: injury.id })) {
        const subInjuries = _.filter(data, { parent_injury_id: injury.id })
        return _.sumBy(subInjuries, subject) > 0 ? _.sumBy(subInjuries, subject) : injury[subject]
      }
      return injury[subject]
    })
  }

  return (
    <div>
      <Overall>
        <div>{t(coverageStatus)}</div>
        <CoverageStatus
          status={coverageStatus}
          progress={coverageProgress}
          playerId={playerId}
          playerName={playerName} />

        <div>{t('Total injuries')}</div>
        <div>{data.length}</div>

        <div>{t('Total recovery days')}</div>
        <div>{sumRecovery('recovery_days')}</div>

        <div>{t('Total missed matches')}</div>
        <div>{sumRecovery('missed_matches')}</div>
      </Overall>
    </div>
  )
}

export default InjuriesOverall

const Overall = styled.div`
  display: none;
  grid-template-columns: min-content min-content;
  gap: 10px 20px;
  margin-top: 15px;
  padding-left: 15px;
  font-weight: 550;
  white-space: nowrap;
  div:nth-child(even) {
    text-align: right;
    color: ${cssvar('injuryColor')};
  }
  @media (max-width: 1024px) {
    padding-left: 0;
  }
  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: 1fr min-content;
    gap: 0;
    margin: 0 -15px;
    & > div {
      padding: 10px 7px;
      border-bottom: 1px solid ${cssvar('borderColor')};
      &:nth-child(odd) {
        padding-left: 15px;
      }
      &:nth-child(even) {
        padding-right: 15px;
      }
    }
  }
`
