import { connect } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import moment from 'moment'
import { BASE_V2_URL } from '../../../api'
import BarChart from '../../Widgets/BarChart'
import PieChart from '../../Widgets/PieChart'
import { Container, H1 } from '../styledComponents'
import {
  CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis,
} from 'recharts'
import { cssvar } from '../../../styles/var'
import styled, { css } from 'styled-components'
import CoverageStatus from '../../Generic/CoverageStatus'

import OKIcon from '../../../images/ok.png'

const Overview = ({
  tag,
  injuries: profileInjuries,
  coverageProgress,
  coverageStatus,
  playerId,
  playerName,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(true)

  const [allInjuries, setInjuries] = useState([])

  const injuries = allInjuries.filter(injury => !injury.other_event && !injury.parent_injury_id)

  const fetchData = useCallback(
    _.debounce(() => {
      async function fetch() {
        setLoading(true)
        try {
          const firstInjury = profileInjuries.sort((a, b) => moment(a.date) - moment(b.date)).find(injury => !injury.other_event && !injury.parent_injury_id)
          const start = firstInjury ? moment(firstInjury.date) : moment('1990-01-01')
          const end = moment(moment.now())
          const timeframe = `${start.format('YYYY-MM-DD')},${end.format('YYYY-MM-DD')}`

          const result = await axios(`${BASE_V2_URL}/players/${tag}/graph?timeframe=${timeframe}&include_other_events=true`)
          setInjuries(result.data.injuries)
          setLoading(false)
        } catch (err) {
          console.log('Unable to load data for', tag)
        }
      }
      fetch()
    }, 300),
    [],
  )

  useEffect(() => {
    fetchData()
  }, [])


  // Career injuries
  const getYear = injury => _.toNumber(_.split(injury.date, '-')[0])
  const thisYear = _.toNumber(moment().format('YYYY'))
  const firstInjuryYear = injuries.length > 0 ? getYear(_.first(injuries)) : undefined
  const years = _.range(firstInjuryYear > thisYear - 7 ? thisYear - 7 : firstInjuryYear, thisYear + 1)
  const injuriesByYear = years.map((year) => {
    const injuriesInYear = injuries.filter(injury => getYear(injury) === year)
    return {
      year,
      injuries: injuriesInYear.length,
    }
  })

  const CustomLabel = ({ x, y, value }) => (
    <text x={x} y={y} dy={4} fill={cssvar('injuryColor')} fontSize={12} textAnchor="middle">
      {value}
    </text>
  )

  const injuriesTypology = _.chain(injuries)
    .groupBy('injury_type')
    .map(inj => ({ name: t(`injury-type-${inj[0].injury_type}`), value: inj.length }))
    .sortBy('value')
    .reverse()
    .value()

  const whereTypology = _.chain(injuries)
    .reject({ where: 'none' })
    .groupBy('where')
    .map(inj => ({ name: t(`injury-match-where-${inj[0].where}`), value: inj.length }))
    .value()

  const traumaTypology = _.chain(injuries)
    .reject({ trauma: 'na' })
    .reject({ trauma: null })
    .groupBy('trauma')
    .map(inj => ({ name: t(`injury-contact-${inj[0].trauma}`), value: inj.length }))
    .value()

  const severityTypology = _.chain(injuries)
    .reject({ recovery_days: null })
    .groupBy((inj) => {
      if (inj.recovery_days < 30) return '< 30 days'
      if (inj.recovery_days >= 30 && inj.recovery_days <= 60) return '30-60 days'
      if (inj.recovery_days > 60) return '> 60 days'
    })
    .sortBy(inj => inj[0].recovery_days)
    .map(inj => ({
      name: t(
        `${inj[0].recovery_days >= 30 && inj[0].recovery_days <= 60
          ? '30-60'
          : inj[0].recovery_days < 30
            ? '< 30'
            : '> 60'
        } days`,
      ),
      value: inj.length,
    }))
    .value()

  const InjuriesTypesWidget = injuriesTypology.length > 3 ? BarChart : PieChart

  const sumRecovery = subject => _.sumBy(injuries, (injury) => {
    if (injury.parent_injury_id) return 0
    if (_.find(injuries, { parent_injury_id: injury.id })) {
      const subInjuries = _.filter(injuries, { parent_injury_id: injury.id })
      return _.sumBy(subInjuries, subject) > 0 ? _.sumBy(subInjuries, subject) : injury[subject]
    }
    return injury[subject]
  })

  if (loading) {
    return <></>
  }

  return (
    <Container>
      <Widgets>
        <Widget fullWidth noMarginOnMobile>
          <WidgetHeader>
            <LeftSide>
              <H1>{t('Career injuries')}</H1>
            </LeftSide>
            {allInjuries && allInjuries.length && (
              <Overall>
                <CoverageStatus
                  status={coverageStatus}
                  progress={coverageProgress}
                  playerId={playerId}
                  playerName={playerName} />
                <Item>
                  <ItemTitle>{t('Total injuries')}</ItemTitle>
                  <ItemValue>{injuries.filter(injury => !injury.parent_injury_id).length}</ItemValue>
                </Item>
                <Item>
                  <ItemTitle>{t('Total recovery days')}</ItemTitle>
                  <ItemValue>{sumRecovery('recovery_days')}</ItemValue>
                </Item>
                <Item>
                  <ItemTitle>{t('Total missed matches')}</ItemTitle>
                  <ItemValue>{sumRecovery('missed_matches')}</ItemValue>
                </Item>
              </Overall>
            )}
          </WidgetHeader>
          <ResponsiveContainer width="100%" height={220}>
            <LineChart
              data={injuriesByYear}
              margin={{
                top: 50, right: 20, left: 20, bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray="1 1" vertical={false} />
              <XAxis
                dataKey="year"
                tickLine={false}
                axisLine={false}
                tick={{ fill: cssvar('secondaryTextColor'), fontSize: 12, dy: 6 }}
                tickMargin={5}
              />
              <Line
                dataKey="injuries"
                type="monotone"
                stroke={cssvar('injuryColor')}
                strokeWidth={3}
                dot={{ stroke: cssvar('injuryColor'), strokeWidth: 2, r: 9 }}
                isAnimationActive={false}
                label={<CustomLabel />}
              />
            </LineChart>
          </ResponsiveContainer>
        </Widget>

        {!loading && (!injuries || !injuries.length) && (
          <Widget fullWidth>
            <EmptyMessage>
              <img src={OKIcon} alt='' />
              {t('no-injuries-in-period')}
            </EmptyMessage>
          </Widget>
        )}

        {injuries && injuries.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Injury types')}</WidgetTitle>
            <InjuriesTypesWidget data={injuriesTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}

        {traumaTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('injury-contact')}</WidgetTitle>
            <PieChart data={traumaTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}

        {whereTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Match/Training')}</WidgetTitle>
            <PieChart data={whereTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}

        {severityTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Severity')}</WidgetTitle>
            <PieChart data={severityTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}
      </Widgets>
    </Container>
  )
}

function mapStateToProps({ filters }) {
  return { filters }
}

export default connect(mapStateToProps)(Overview)


const Widgets = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`
const Widget = styled.div`
  ${props => props.fullWidth
    && css`
      @media (min-width: 1025px) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    `};
  min-width: 0;
  padding: 15px;
  background-color: #fff;
   ${props => props.noMarginOnMobile
    && css`
      @media (max-width: 1025px) {
        padding: 0;
      }
    `};
`

const WidgetTitle = styled.h4`
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 500;
  span {
    display: inline-block;
    vertical-align: middle;
    margin-left: 7px;
    margin-top: -2px;
    padding: 3px 8px;
    border: 2px solid ${cssvar('injuryColor')};
    border-radius: 100px;
    color: ${cssvar('injuryColor')};
    font-size: 13px;
    font-weight: 650;
    line-height: 1;
  }
  @media (max-width: 640px) {
    font-size: 18px;
    span {
      margin-left: 5px;
      font-size: 11px;
    }
  }
`

const EmptyMessage = styled.div`
  font-size: 18px;
  font-weight: 550;
  text-align: center;
  opacity: 0.75;
  img {
    display: block;
    height: 55px;
    margin: 0 auto;
    margin-bottom: 5px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    img {
      height: 45px;
    }
  }
`

const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 20px;
    align-items: stretch;
    max-width: 100%;
    h1 {
      margin: 0;
      padding: 15px;
      padding-bottom: 0px;
    }
  }

`


const LeftSide = styled.div`
  flex: 0 0 auto;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  h1 {
    margin: 0;
    padding: 0px;
  }
`


// Overall

const Overall = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  gap: 30px;
  @media (max-width: 1200px) {
    gap: 0px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
    align-items: stretch;
  }
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  @media (max-width: 800px) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 10px 15px;
    &:not(:last-child) {
      border-bottom: 1px solid ${cssvar('borderColor')};
    }
  }
`
export const ItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 800px) {
  }
`
export const ItemValue = styled.div`
  color: ${cssvar('injuryColor')};
  font-size: 18px;
  font-weight: 550;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`
